* {
  padding: 0;
  margin: 0;
}

:root {
  --white-color: #fff;
  --bg-color: rgba(1, 110, 204, 0.06);
  --primary-color: #fff;
  --secondary-color: #fff;
  --tertary-color: #19d2fe;
  --first-color: #016ecc;
  --second-color: #f0f6fc;
  /* --linear-background : linear-gradient(100deg, #016ecc 0%, #19d2fe 100%); */
  --linear-background: linear-gradient(150deg, #1b1b1c 50%, #19d2fe 100%);
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  /* width: 99%;
  height: 100%; */

  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* BUTTONS  */

.btnTypeOne a {
  padding: 15px 40px;
  background-color: #ffffff;
  /* background-color: black; */
  color: #016ecc;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 100px;
  text-transform: uppercase;
  text-align: center;
}

.btnTypeOne a:hover {
  background-color: transparent;
  color: #fff;
  transition: all 1s ease-in-out;
}

.btnTypeTwo a {
  padding: 10px 40px;
  /* background-color: #016ecc; */
  background-color: black;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 100px;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid #016ecc;
}

.btnTypeTwo a:hover {
  background-color: transparent;
  color: #016ecc;
  transition: all 0.5s ease-in-out;
}

.btnTypeThree a {
  padding: 10px 40px;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 100px;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid #fff;
}

.btnTypeThree a:hover {
  background-color: #fff;
  color: #000;
  transition: all 1s ease-in-out;
}

header {
  padding: 5px 15px;
  /* background-image: linear-gradient(30deg, #016ecc 0%, #19d2fe 100%); */
  background-color: black;
}

header .desktopMode .logoContainer a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
}

header .desktopMode nav a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding: 40px 10px 0 10px;
  font-family: "Nunito Sans", Sans-serif;
}

header .desktopMode nav #hamburgerIcon {
  display: none;
}

header #mobileNav {
  background-color: #000;
  width: 100%;
}

header #mobileNav li {
  list-style: none;
  display: block;
  padding: 10px 0;
  width: 100%;
  text-align: center;
}

header #mobileNav li:hover {
  background-image: linear-gradient(100deg, #016ecc 0%, #19d2fe 100%);
  color: #000;
}

header #mobileNav li a {
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
}

header .activateMobileNav {
  display: none;
}

/* ------------------- first container  */

.firstContainer {
  padding-top: 0px;
  background-color: transparent;
  /* background-image: var(--linear-background); */
  background: black;
}

/* ------------------ second container  */

.secondContainer {
  padding: 94px 0px 0px 62px; 
  position: relative;
}

.secondContainer .elementor-background-overlay {
  background-image: url(https://demo.themovation.com/stratus/wp-content/uploads/2019/06/CTA-bg.png);
  background-position: center center;
  background-size: cover;
  opacity: 0.37;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.secondContainer .secondContainer-leftSide h5 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.secondContainer .secondContainer-leftSide h2 {
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5em;
  /* text-transform: capitalize; */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.secondContainer .secondContainer-leftSide h4 {
  color: #ffffff;
  font-size: 22px;
  line-height: 1.45em;
  letter-spacing: 0;
}

.secondContainer .secondContainer-rightSide img {
  width: 100%;
  height: auto;
  margin: -49px 0 0 -36px;
  z-index: 1;
  position: relative;
}

.secondContainer .secondContainer-leftSide .secondContainer-btn {
  margin-top: 40px;
}

.secondContainer .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.secondContainer .elementor-shape-bottom {
  bottom: -1px;
}

.secondContainer .elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

.secondContainer .elementor-shape-bottom svg {
  width: calc(120% + 1.3px);
  height: 110px;
  transform: translateX(-50%) rotateY(180deg);
}

.secondContainer .elementor-shape svg {
  display: block;
  width: calc(120% + 1.5px);
  position: relative;
  left: 50%;
}

/* --------------  THIRD CONTAINER */

.thirdContainer {
  padding: 41px 0 104px 0;
  position: relative;
  background-color: #fff;
}

.thirdContainer .iconsContainer > div {
  padding: 0 10px;
}

/* ------------  FORTH CONTAINER ----------------  */

.forthContainer {
  /* background-color: var(--second-color); */
  background-color: black;
  position: relative;
}

.forthContainer .desc > div {
  margin-bottom: 18px;
}

.forthContainer .desc h5 {
  /* color: #016ecc; */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.forthContainer .desc h1 {
  font-size: 45px;
  font-weight: bold;
  line-height: 1.2em;
  /* color: #000; */
  color: #ffff;
  letter-spacing: 0.1px;
  font-weight: 500;
}

.forthContainer .desc p {
  font-size: 18px;
  line-height: 2em;
  /* color: #000; */
color: #fff;
  font-weight: 500;
}

.forthContainer .tripleBox {
  margin-top: 85px;
  padding-bottom: 120px;
}

.forthContainer .tripleBox .tripleBox-card {
  box-shadow: 0px 0px 40px 10px rgba(1, 110, 204, 0.05);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px;
  padding: 75px 30px;
  /* background-color: red!important; */
}

.forthContainer .tripleBox .tripleBox-card h3 {
  font-size: 27px;
  font-weight: 500;
  line-height: 1.35em;
  letter-spacing: 0.4px;
  color: #fff;
}

.forthContainer .tripleBox .tripleBox-card p {
  font-size: 18px;
  color: #fff
}

/* Wave Bottom */

.forthContainer .bottomWave .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.forthContainer .bottomWave .elementor-shape-bottom {
  bottom: -1px;
}

.forthContainer .bottomWave .elementor-shape .elementor-shape-fill {
  fill: #fff;
  
  transform-origin: center;
  transform: rotateY(0deg);
}

.forthContainer .bottomWave .elementor-shape-bottom svg {
  width: calc(170% + 1.3px);
  height: 110px;
  transform: translateX(-50%) rotateY(0deg);
}

.forthContainer .bottomWave .elementor-shape svg {
  display: block;
  width: calc(170% + 1.3px);
  position: relative;
  left: 50%;
}

/* Wave TOP */

.forthContainer .topWave .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.forthContainer .topWave .elementor-shape-bottom {
  top: -70px;
}

.forthContainer .topWave .elementor-shape .elementor-shape-fill {
  /* fill: var(--second-color); */
  fill: black;
  transform-origin: center;
  transform: rotateY(0deg);
}

.forthContainer .topWave .elementor-shape-bottom svg {
  width: calc(140% + 1.3px);
  height: 70px;
  transform: translateX(-50%) rotateY(180deg);
}

.forthContainer .topWave .elementor-shape svg {
  display: block;
  width: calc(140% + 1.3px);
  position: relative;
  left: 50%;
}

/* -----  FIFTH CONTAINER   */

.fifthContainer {
  background-color: #fff;
}

.fifthContainer .twoSection .twoSection-left img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.fifthContainer .twoSection .twoSection-right > div {
  margin-bottom: 10px;
}

.fifthContainer .twoSection .twoSection-right h6 {
  color: #016ecc;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.5em;
}

.fifthContainer .twoSection .twoSection-right h3 {
  color: #000;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.3em;
}

.fifthContainer .twoSection .twoSection-right p {
  color: #000;
  font-size: 18px;
  line-height: 2em;
  letter-spacing: 1px;
}

.fifthContainer .twoSection .twoSection-right .fifthContainer-btn1 {
  margin-top: 30px;
}

.fifthContainer .twoSection .twoSection-right .fifthContainer-btn1 a {
  padding: 10px 40px;
  font-size: 15px;
}

.fifthContainer .twoSection .twoSection-right .fifthContainer-btn2 {
  margin-top: 30px;
}

.fifthContainer .twoSection .twoSection-right .fifthContainer-btn2 a {
  padding: 10px 40px;
  font-size: 15px;
}

/* ----- sixth container  */

.sixthContainer {
  /* background-color: rgba(1, 110, 204, 0.06); */
  /* background: var(--linear-background); */
  background-color: black;
  position: relative;
 
}

.sixthContainer .desc > div { 
  margin-bottom: 10px;
}

.sixthContainer .desc h6 {
  color: #016ecc;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sixthContainer .desc h2 {
  font-size: 46px;
  color: #fff;
  font-weight: bold;
  line-height: 1.2em;
  font-weight: 500;
}

.sixthContainer .desc h5 {
  font-size: 19px;
  line-height: 1.5em;
  color: #fff;
  font-weight: 500;
}

.sixthContainer .tripleBox {
  margin-top: 60px;
  /* margin-bottom: 200px; */
}

.sixthContainer .tripleBox .threeBoxes-card {
  padding: 50px 40px 40px 40px;
  box-shadow: 0px 0px 40px 10px rgba(1, 110, 204, 0.05);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 20px 0px 20px;
  position: relative;
  z-index: 1;
  height: 350px;
  background-color: transparent;
  border: 1px solid #fff;
}

.sixthContainer .tripleBox .threeBoxes-card > div {
  margin-bottom: 15px;
}

.sixthContainer .tripleBox .threeBoxes-card h4 {
  /* color: #1d2023; */
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

.sixthContainer .wave {
  padding: 1px;
}

.sixthContainer .tripleBox .threeBoxes-card p {
  /* color: #333; */
color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* Wave TOP */

.sixthContainer .topWave .elementor-shape {
  /* overflow: hidden; */
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.sixthContainer .topWave .elementor-shape-bottom {
  top: -112px;
  position: relative;
  transform: rotate(0deg);
}

.sixthContainer .topWave .elementor-shape .elementor-shape-fill {
  /* fill:rgba(1, 110, 204, 0.06); */
  /* fill: red; */
  color: black;
  transform-origin: center;
  transform: rotateY(0deg);
}

.sixthContainer .topWave .elementor-shape-bottom svg {
  width: calc(170% + 1.3px);
  height: 65px;
  transform: translateX(-50%);
}

.sixthContainer .topWave .elementor-shape svg {
  display: block;
  width: calc(170% + 1.3px);
  position: relative;
  left: 50%;
}

/* ------------------- seventh container  */

.seventhContainer {
  background-color: transparent;
  /* background-image: linear-gradient(100deg, #016ecc 0%, #19d2fe 100%); */
  background: black;
  padding: 60px 0;
  position: relative;
}

.seventhContainer h3 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.3em;
}

/* Wave bottom */

.seventhContainer .topWave .elementor-shape {
  /* overflow: hidden; */
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.seventhContainer .topWave .elementor-shape-bottom {
  transform: rotate(0deg);
  top: -129px;
}

.seventhContainer .topWave .elementor-shape .elementor-shape-fill {
  /* fill: linear-gradient(100deg, #016ecc 0%, #19d2fe 100%); */
  /* fill: #0c9ce3; */
  fill: black;
  transform-origin: center;
  transform: rotateY(0deg);
}

.seventhContainer .topWave .elementor-shape-bottom svg {
  width: calc(125% + 1.3px);
  height: 129px;
}

.seventhContainer .topWave .elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1px;
}

/* calculator page  */

#calculatorPage {
  background-color: #f0f6fc;
  padding: 30px 50px;
}

.cryptoCalculator {
  background-color: var(--text-color);
  border-radius: 10px;
}

.calculationResult {
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  padding: 30px 50px;
}

/* BLOG PAGE  */

#blogPage {
  background-color: rgb(243 244 246/1);
  padding: 50px 30px;
}

#blogPage .blogContainer {
  /* position: relative; */
}

#blogSubHeader {
  padding: 15px 0;
  box-shadow: 0px 12px 5px 0px rgba(221, 221, 221, 0.75);
  -webkit-box-shadow: 0px 12px 5px 0px rgba(221, 221, 221, 0.75);
  -moz-box-shadow: 0px 12px 5px 0px rgba(221, 221, 221, 0.75);
}

#blogSubHeader > div {
  padding-left: 15px;
}

#blogSubHeader div a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

#blogPage .blogContainer article {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;

  box-shadow: 9px 12px 9px -1px rgba(221, 221, 221, 1);
  -webkit-box-shadow: 9px 12px 9px -1px rgba(221, 221, 221, 1);
  -moz-box-shadow: 9px 12px 9px -1px rgba(221, 221, 221, 1);
}

#blogPage .blogContainer article > div {
  padding-bottom: 5px;
}

#blogPage .blogContainer article .title a {
  text-decoration: none;
}

#blogPage .blogContainer article .title a h2 {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #1f2937;
}

#blogPage .blogContainer article .title a:hover h2 {
  color: #016ecc;
  cursor: pointer;
  text-decoration: underline;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#blogPage .blogContainer article .date > div {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-right: 15px;
  color: #4b5563;
  font-weight: 400;
}

#blogPage .blogContainer article .date .avatarImage img {
  width: 40px;
  height: 40px;
}
#blogPage .blogContainer article .description p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: #282828;
}

#blogPage .blogContainer article .btn {
  background-color: #016ecc;
  padding: 5px 30px;
  text-decoration: none;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
}

#blogPage .blogContainer article .btn:hover {
  /* text-decoration: underline; */
  background-color: #0046a4;
  transition: all 0.5s ease-in-out;
}

#blogPage .blogContainer .nestedBlogs {
  padding: 10px 0 20px 14%;
}

#blogPage .blogContainer .nestedBlogs > div {
  position: relative;
}

#blogPage .blogContainer .sectionHeading {
  position: relative;
  background: #000;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 60px;
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
}

/* #blogPage .blogContainer .sectionHeading::before {

  content: "";
  position: absolute;
  width: 3px;
  background-color: black;
  height: 100px;
  border-radius: 10px;
  top: 36px;
  left: 3.5%;


} */

/* 
  
     background: url(https://static.thenounproject.com/png/657408-200.png);
    background-size: 74px calc(100% - 0.5em);
    background-position: top 0 left 5px;
    padding-left: 0;
    background-repeat: no-repeat;
  
  */

/* 
  ---

  background-image: url(https://www.pngall.com/wp-content/uploads/11/Horizontal-Line-PNG-Images.png);
    background-position: top 41px left 39px;
    background-size: 296px calc(100% - 0.5em);
    background-repeat: no-repeat;


  */

#verticalImage {
  /* background-image: url(https://www.pngall.com/wp-content/uploads/11/Horizontal-Line-PNG-Images.png);
    background-position: top 41px left 39px;
    background-size: 296px calc(100% - 0.5em);
    background-repeat: no-repeat; */

  background-color: black;
  position: absolute;
  top: 50%;
  left: -62px;
  width: 6%;
  height: 2px;
}

#blogPostPage {
  background-color: #f3f4f6;
}

#blogPostPage .title h1 {
  font-weight: 700;
  font-size: 35px;
  color: #000;
}

#blogPostPage .date > div {
  font-size: 1rem;
  line-height: 1.25rem;
  padding-right: 15px;
  color: #4b5563;
  font-weight: 500;
}

#blogPostPage .articleDescription {
  font-size: 18px;
  font-weight: 500;
  color: #1f2937;
}

#allBlogs table thead tr th {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
}

#allBlogs table tbody tr th {
  font-size: 12px;
  font-weight: 500;
  color: #282828;
}

#allBlogs table tbody tr td {
  font-size: 12px;
  font-weight: 500;
  color: #282828;
}

footer {
  padding: 40px 50px;
  background-color: #ffff;
}

footer .heading h3 {
  color: #1a1a1a;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

footer .lowerNav {
  margin-top: 20px;
}

footer .lowerNav > div {
  margin-bottom: 15px;
}

footer nav a {
  text-decoration: none;
  color: #000000c7;
}

footer nav .iconText {
  padding: 0 0 0 10px;
}

footer .desc {
  color: #000000c7;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.8rem;
}

footer .lowerSection .socialIcons > a {
  padding: 0 20px 0 0;
}

/* RESPONSIVE  */

@media only screen and (max-width: 1130px) {
  /* .sixthContainer {
    background-color: red;
  } */

  .secondContainer {
    padding: 54px 0px 0px 62px;
  }

  .secondContainer .secondContainer-leftSide h5 {
    color: #ffffff;
    font-size: 16px;
  }

  .secondContainer .secondContainer-leftSide h1 {
    font-size: 48px;
  }

  .secondContainer .secondContainer-leftSide h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 992px) {
  /* .sixthContainer {
    background-color: orange;
  } */

  .secondContainer {
    padding: 40px 0px 40px 62px;
  }

  .secondContainer .secondContainer-leftSide h5 {
    color: #ffffff;
    font-size: 16px;
  }

  .secondContainer .secondContainer-leftSide h1 {
    font-size: 35px;
  }

  .secondContainer .secondContainer-leftSide h3 {
    font-size: 15px;
  }

  .secondContainer .secondContainer-leftSide .secondContainer-btn {
    margin-top: 30px !important;
  }

  .secondContainer .secondContainer-leftSide .secondContainer-btn a {
    padding: 10px 40px;
    font-size: 16px;
  }

  /* ------ FORTH CONTAINER --  */

  .forthContainer .tripleBox {
    margin-top: 30px;
    padding-bottom: 0px;
    z-index: 1;
    position: relative;
  }

  .forthContainer .tripleBox .tripleBox-card {
    margin: 0px 5px;
    padding: 35px 10px;
  }

  .forthContainer .tripleBox .tripleBox-card h3 {
    font-size: 25px;
  }

  .forthContainer .tripleBox .tripleBox-card p {
    font-size: 15px;
  }

  /* -----  FIFTH CONTAINER   */

  .fifthContainer .twoSection .twoSection-right > div {
    margin-bottom: 10px;
  }

  .fifthContainer .twoSection .twoSection-right h3 {
    font-size: 31px;
  }

  .fifthContainer .twoSection .twoSection-right p {
    font-size: 13px;
  }

  .fifthContainer .twoSection .twoSection-right .fifthContainer-btn {
    margin-top: 30px;
  }

  .fifthContainer .twoSection .twoSection-right .fifthContainer-btn a {
    padding: 10px 40px;
    font-size: 15px;
  }

  /* ----- sixth container  */

  .sixthContainer {
    position: relative;
  }

  .sixthContainer .desc > div {
    margin-bottom: 10px;
  }

  .sixthContainer .desc h6 {
    font-size: 16px;
  }

  .sixthContainer .desc h2 {
    font-size: 46px;
  }

  .sixthContainer .desc h5 {
    font-size: 18px;
  }

  .sixthContainer .tripleBox {
    margin-top: 60px;
  }

  .sixthContainer .tripleBox .threeBoxes-card {
    padding: 30px 20px;
  }

  .sixthContainer .tripleBox .threeBoxes-card > div {
    margin-bottom: 15px;
  }

  .sixthContainer .tripleBox .threeBoxes-card h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  header .desktopMode nav #hamburgerIcon {
    display: block;
  }

  header .desktopMode nav a {
    display: none;
  }

  header {
    padding: 5px 15px;
    background-color: #000;
    opacity: 0.8;
  }

  header .desktopMode .logoContainer a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
  }

  header .desktopMode nav a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    padding: 40px 10px 0 10px;
    font-family: "Nunito Sans", Sans-serif;
  }

  .sixthContainer {
    background-color: green;
  }

  .secondContainer {
    padding: 40px 0px 70px 62px;
  }

  .secondContainer .secondContainer-leftSide h5 {
    color: #ffffff;
    font-size: 10px;
  }

  .secondContainer .secondContainer-leftSide h1 {
    font-size: 25px;
  }

  .secondContainer .secondContainer-leftSide h3 {
    font-size: 12px;
  }

  .secondContainer .secondContainer-rightSide img {
    margin: 0px 0 0 -10px;
  }

  .secondContainer .secondContainer-leftSide .secondContainer-btn {
    margin-top: 20px !important;
  }

  .secondContainer .secondContainer-leftSide .secondContainer-btn a {
    padding: 10px 40px;
    font-size: 12px;
  }

  .forthContainer .desc > div {
    margin-bottom: 10px;
  }

  .forthContainer .desc h1 {
    font-size: 35px;
  }

  .forthContainer .desc p {
    font-size: 13px;
  }

  .forthContainer .tripleBox .tripleBox-card {
    margin: 0px 10px;
    padding: 35px 10px;
  }

  .forthContainer .tripleBox .tripleBox-card h3 {
    font-size: 23px;
  }

  .forthContainer .tripleBox .tripleBox-card p {
    font-size: 13px;
  }

  /* -----  FIFTH CONTAINER   */

  .fifthContainer .twoSection .twoSection-right > div {
    margin-bottom: 10px;
  }

  .fifthContainer .twoSection .twoSection-right h3 {
    font-size: 31px;
  }

  .fifthContainer .twoSection .twoSection-right p {
    font-size: 13px;
  }

  .fifthContainer .twoSection .twoSection-right .fifthContainer-btn1 {
    margin-top: 30px;
  }

  .fifthContainer .twoSection .twoSection-right .fifthContainer-btn1 a {
    padding: 10px 40px;
    font-size: 15px;
  }

  .fifthContainer .twoSection .twoSection-right .fifthContainer-btn2 {
    margin-top: 30px;
  }

  .fifthContainer .twoSection .twoSection-right .fifthContainer-btn2 a {
    padding: 10px 40px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 576px) {
  /* .sixthContainer {
    background-color: blue;
  } */

  .secondContainer {
    padding: 40px 0px 0px 30px;
  }

  .secondContainer .secondContainer-leftSide h5 {
    color: #ffffff;
    font-size: 16px;
  }

  .secondContainer .secondContainer-leftSide h1 {
    font-size: 40px;
  }

  .secondContainer .secondContainer-leftSide h3 {
    font-size: 12px;
  }

  .secondContainer .secondContainer-rightSide img {
    margin: 0px 0 0 -20px;
  }

  .secondContainer .secondContainer-leftSide .secondContainer-btn {
    margin-top: 40px !important;
  }

  .secondContainer .secondContainer-leftSide .secondContainer-btn a {
    padding: 10px 40px;
    font-size: 20px;
  }

  /* --- forth container */

  .forthContainer .desc > div {
    margin-bottom: 20px;
  }

  .forthContainer .desc h5 {
    font-size: 18px;
  }

  .forthContainer .desc h1 {
    font-size: 40px;
  }

  .forthContainer .desc p {
    font-size: 15px;
  }

  .forthContainer .tripleBox {
    margin-top: 20px;
  }

  .forthContainer .tripleBox .tripleBox-card {
    margin: 0px 10px;
    padding: 35px 20px;
  }

  .forthContainer .tripleBox .tripleBox-card h3 {
    font-size: 25px;
  }

  .forthContainer .tripleBox .tripleBox-card p {
    font-size: 15px;
  }

  /* -----  FIFTH CONTAINER   */

  .fifthContainer .twoSection .twoSection-right h3 {
    font-size: 35px;
  }

  .fifthContainer .twoSection .twoSection-right p {
    font-size: 15px;
  }
}
